import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const HeroDisclaimerWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 0;
  ${atMinWidth.md`
    margin-top: 128px;
  `}

  display: 'flex';
  color: ${colors.primary[400]};
  font-size: 12px;

  .hero-disc-col {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
`;
